import { SideNavigation } from '@amzn/awsui-components-react';
import { useHistory, useLocation } from 'react-router';

const items: (SideNavigation.Link | SideNavigation.LinkGroup)[] = [
    {
        type: 'link-group',
        text: 'Interludes',
        href: '/interludes',
        items: [
            {
                type: 'link',
                text: 'Create interlude',
                href: '/interludes/create-interlude',
            },
            {
                type: 'link',
                text: 'Bulk upload',
                href: '/interludes/upload-interludes',
            },
        ],
    },
    {
        type: 'link-group',
        text: 'Assets',
        href: '/assets',
        items: [
            {
                type: 'link',
                text: 'Create asset',
                href: '/assets/create-asset',
            },
            {
                type: 'link',
                text: 'Bulk upload',
                href: '/assets/upload-assets',
            },
        ],
    },
    {
        type: 'link',
        text: 'Sequencing',
        href: '/qcars',
    },
    {
        type: 'link-group',
        text: 'Artist Commentaries',
        href: '/artist-commentaries',
        items: [
            {
                type: 'link',
                text: 'Create Artist Commentaries',
                href: '/artist-commentaries/create',
            },
        ],
    },
    {
        type: 'link',
        text: 'Voice Comments',
        href: '/voice-comments',
    },
    {
        type: 'link',
        text: 'Queue',
        href: '/queue',
    },
    {
        type: 'link',
        text: 'Staged changes',
        href: '/changes',
    },
    {
        type: 'link',
        text: 'Create report',
        href: '/reports/create-report',
    },
];

function Navigation(): JSX.Element {
    const location = useLocation();
    const history = useHistory();
    const follow = (e: CustomEvent<SideNavigation.FollowDetail>) => {
        e.preventDefault();
        history.push(e.detail.href);
    };
    return (
        <SideNavigation
            header={{ text: 'Forge', href: '/' }}
            items={items}
            activeHref={location.pathname}
            onFollow={follow}
        />
    );
}

export default Navigation;
