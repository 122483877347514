import { ExperienceSearchInput, ExperienceType, MusicExperienceSearchItem } from '@amzn/mousai-service-client';
import { generateUniqueId } from '@amzn/awsui-components-react-v3/uxdg/internal/hooks/use-unique-id';
import { CommentaryAssociationType, CommentaryMousaiState, ForgeCommentaryExperience } from './commentaryDataTypes';
import {
    getArtistAsinFromCommentaryExperience,
    getAudioAssetPresignedUrl,
    getSecondaryText,
    getImageAssetPresignedUrl,
    getPlayableEntity,
} from '../mousaiUtil';
import { isValidTextValue } from './validation';
import { CommentaryAsins } from '../../components/artist-commentaries/EditArtistCommentary';
import _ from 'lodash';

export function convertMousaiDataToForgeExperiences(
    commentaries: MusicExperienceSearchItem[],
): ForgeCommentaryExperience[] {
    const experiences: ForgeCommentaryExperience[] = [];
    commentaries.forEach((exp) => {
        const subForm = convertMousaiDataToForgeExperience(exp);
        if (subForm) {
            experiences.push(subForm);
        }
    });
    return experiences;
}

export function retrieveAlbumCommentary(
    experiences: ForgeCommentaryExperience[],
): ForgeCommentaryExperience | undefined {
    return _(experiences)
        .filter((e) => e.associationType === 'ALBUM')
        .find();
}

export function retrieveTrackCommentaries(experiences: ForgeCommentaryExperience[]): ForgeCommentaryExperience[] {
    return _(experiences)
        .filter((e) => e.associationType === 'TRACK')
        .value();
}

export function createNewForgeExperience(
    associationType: CommentaryAssociationType,
    associationAsin?: string,
): ForgeCommentaryExperience {
    const uuid = generateUniqueId();
    return {
        didExperienceChange: false,
        isForDeletion: false,
        mousaiState: 'LOCAL',
        key: uuid,
        associationType: associationType,
        associationAsin: associationAsin,
        isExplicit: false,
    };
}

export function buildSearchCommentaryForAsinQuery(asin: string): ExperienceSearchInput {
    return {
        query: {
            owner: {
                id: asin,
                idType: 'ASIN',
            },
            experienceTypes: [ExperienceType.COMMENTARY],
        },
        includeAssets: true,
    };
}

function convertMousaiDataToForgeExperience(exp: MusicExperienceSearchItem): ForgeCommentaryExperience | undefined {
    if (!exp.assets) {
        return;
    }
    const associatedPlayableEntity = getPlayableEntity(exp);
    const mousaiState = exp.state === 'DRAFT' || exp.state === 'LIVE' ? (exp.state as CommentaryMousaiState) : 'LOCAL';
    const associationType =
        associatedPlayableEntity?.entityType === 'ALBUM' || associatedPlayableEntity?.entityType === 'TRACK'
            ? (associatedPlayableEntity.entityType as CommentaryAssociationType)
            : undefined;
    return {
        didExperienceChange: false,
        isForDeletion: false,
        mousaiState: mousaiState,
        key: exp.experienceId,
        associationAsin: associatedPlayableEntity?.identifier.id,
        associationType: associationType,
        secondaryText: getSecondaryText(exp),
        isExplicit: exp.eligibility.isExplicit,
        presignedAudioUrl: getAudioAssetPresignedUrl(exp),
        presignedImageUrl: getImageAssetPresignedUrl(exp),
        primaryText: exp.title,
    };
}

export function retrieveAsinsFromMousai(exp: MusicExperienceSearchItem): [CommentaryAsins, boolean] {
    const albumAsin = exp.owner.id;
    const artistAsin = getArtistAsinFromCommentaryExperience(exp);
    const isValidAlbumAsin = isValidTextValue(albumAsin);
    const isValidArtistAsin = isValidTextValue(artistAsin);
    return [
        {
            album: albumAsin,
            artist: artistAsin,
        },
        isValidAlbumAsin && isValidArtistAsin,
    ];
}
