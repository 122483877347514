import React from 'react';
import {
    InterludeTableItem,
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    COLUMN_DEFINITIONS,
    SORTABLE_COLUMNS,
} from '../configs/interludes-table-config';
import {
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    TableWrapLines,
    Modal,
    Button,
    Flashbar,
} from '@amzn/awsui-components-react';
import { InterludeTableButtons } from './InterludesTableHeader';
import { TableNoMatchState } from './commons/common-components';
import { createPropertyStorage } from '../utils/jsonStorage';
import {
    fetchDeleteInterlude,
    fetchGetInterludePage,
    fetchGetAssociationPage,
    fetchExportInterludes,
    CsvExport,
} from '../utils/fetchUtil';
import { addAssociations } from '../utils/associationsUtil';
import { CSVLink } from 'react-csv';
import { FlashbarItem, itemError } from './commons/flash-messages';
import { RouteStateComponent, Props } from './RouteState';
import {
    addToColumnDefinitions,
    addToContentDescriptionGroups,
    ColumnSetting,
    DEFAULT_TABLE_OPTIONS,
    filteringFunction,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    mapWithContentSelectionValues,
    TableOptions,
} from '../utils/tableUtil';
import { DataStage, PropsWithDataStage, withDataStage } from './StageContext';
import { ServerInterlude } from '../data-types';
import { CatalogStage, InterludeAssociationOutput } from '@amzn/amazon-music-interlude-catalog-manager-client';
const columnStorage = createPropertyStorage<ColumnSetting[]>('Interludes-Table-Settings');
const optionsStorage = createPropertyStorage<TableOptions>('Interlude-Table-Options');

let globalCachedItems: ServerInterlude[];

export interface State extends TableOptions {
    columnDefinitions: Table.ColumnDefinition[];
    contentSelector: TableContentSelector.ContentDescriptionGroup[];
    selectedTemplates: InterludeTableItem[];
    templates: InterludeTableItem[];
    showDeleteModal: boolean;
    loading: boolean;
    firstPageDone: boolean;
    dataToDownload: CsvExport;
    exporting: boolean;
    deleting: boolean;
    flashbar: FlashbarItem[];
    filteringText?: string;
    sortingDetail: TableSorting.SortingChangeDetail;
}

export class InterludesTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columnDefinitions: COLUMN_DEFINITIONS,
            contentSelector: CONTENT_SELECTOR_OPTIONS,
            selectedTemplates: [],
            templates: [],
            showDeleteModal: false,
            loading: true,
            firstPageDone: false,
            dataToDownload: [],
            exporting: false,
            deleting: false,
            flashbar: [],
            sortingDetail: { sortingColumn: COLUMN_DEFINITIONS[0].id, sortingDescending: true },
            ...DEFAULT_TABLE_OPTIONS,
        };
    }

    csvLink = React.createRef<any>();
    unloaded = false;
    historyState = new RouteStateComponent(this.props, this.state);

    saveColumnWidth = async (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        const existing = await columnStorage.load();
        columnStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths, existing));
    };

    saveColumnSelection = async (e: CustomEvent<Table.ContentSelectionChangeDetail>) => {
        if (!e.detail.contentSelection) {
            return;
        }
        const existing = await columnStorage.load();
        columnStorage.save(mapWithContentSelectionValues(COLUMN_DEFINITIONS, e.detail.contentSelection, existing));
    };

    loadSettings = async () => {
        const columns = (await columnStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns);
        const contentSelector = addToContentDescriptionGroups(CONTENT_SELECTOR_OPTIONS, columns);
        this.setState({ columnDefinitions, contentSelector, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    sortingChanged = (e: CustomEvent<TableSorting.SortingChangeDetail>) => {
        this.setState({ sortingDetail: e.detail }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems(true);
        this.loadSettings();
    }

    componentWillUnmount(): void {
        // Use this flag to signal any background tasks that the component is being
        // destroyed (i.e. user navigated away from the page)
        this.unloaded = true;
    }

    componentDidUpdate(prevProps: PropsWithDataStage): void {
        if (this.props.dataStage != prevProps.dataStage) {
            this.setState({ flashbar: [] });
            this.loadItems();
        }
    }

    loadItems = async (useCache?: boolean) => {
        if (useCache && globalCachedItems) {
            this.setState({
                templates: globalCachedItems,
                loading: false,
                firstPageDone: true,
            });
            return;
        }
        this.setState({
            loading: true,
            firstPageDone: false,
        });
        this.props.history.replace({ ...this.props.location });
        let cachedItems: ServerInterlude[] = [];
        try {
            // Fetch associations in live and sandbox and then stitch them together
            const loadAllAssociations = async (stage: CatalogStage): Promise<InterludeAssociationOutput[]> => {
                const associations: InterludeAssociationOutput[] = [];
                let nextToken: string | undefined;
                do {
                    const result = await fetchGetAssociationPage(stage, nextToken);
                    associations.push(...result.associations);
                    nextToken = result.nextToken;
                } while (nextToken && !this.unloaded);
                return associations;
            };

            const liveAssociations = loadAllAssociations(CatalogStage.LIVE);
            const sandboxAssociations =
                this.props.dataStage == DataStage.Sandbox ? loadAllAssociations(CatalogStage.SANDBOX) : undefined;

            // Fetch live interludes, concatenate to SANDBOX interludes if in SANDBOX
            const loadAllInterludes = async (stage: CatalogStage) => {
                let nextToken: string | undefined;
                do {
                    const result = await fetchGetInterludePage(stage, nextToken);
                    const deduped = result.interludes.filter((il) => !cachedItems.find((dup) => dup.id == il.id));
                    cachedItems = cachedItems.concat(deduped);
                    if (this.state.pageSize <= 100) {
                        this.setState({
                            firstPageDone: true,
                            templates: cachedItems,
                        });
                    }
                    nextToken = result.nextToken;
                } while (nextToken && !this.unloaded);
            };

            // Load SANDBOX first in order for the dedupe logic to work as expected
            if (this.props.dataStage == DataStage.Sandbox) {
                await loadAllInterludes(CatalogStage.SANDBOX);
            }
            await loadAllInterludes(CatalogStage.LIVE);

            let associations: InterludeAssociationOutput[];
            // Wait for the associations from LIVE
            associations = await liveAssociations;
            cachedItems = addAssociations(cachedItems, associations);

            if (sandboxAssociations != undefined) {
                associations = await sandboxAssociations;
                cachedItems = addAssociations(cachedItems, associations);
            }
            this.setState({
                firstPageDone: true,
                templates: cachedItems,
                loading: false,
            });

            // Only store the results in the global cache if the user did not navigate away while loading
            if (!this.unloaded) {
                globalCachedItems = cachedItems;
            }
        } catch (error) {
            this.setState({
                loading: false,
                flashbar: [itemError('Failed to load interludes', error)],
            });
        }
    };

    onSelectionChange(evt: CustomEvent<TableSelection.SelectionChangeDetail<InterludeTableItem>>) {
        this.setState({
            selectedTemplates: evt.detail.selectedItems,
        });
    }

    openDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    // Handler for Items delete
    onItemsDelete = () => {
        this.setState({ deleting: true });
        const deleteIds = this.state.selectedTemplates.map((template) => template.id);
        const deletePromises = deleteIds.map((id) => fetchDeleteInterlude(id, this.props.dataStage));
        Promise.all(deletePromises)
            .then(() => {
                const remainingTemplates = this.state.templates.filter(
                    (template) => deleteIds.indexOf(template.id) < 0,
                );
                this.setState({ templates: remainingTemplates });
            })
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to delete one or more interludes', error)] });
            })
            .finally(() => {
                this.setState({ deleting: false });
            });
    };

    // Handler for Export to csv
    onItemsExport = () => {
        const interludeIds = this.state.selectedTemplates.map((interlude) => interlude.id);
        this.setState({ dataToDownload: [], exporting: true });
        fetchExportInterludes(this.props.dataStage, interludeIds)
            .then((dataToDownload) => {
                this.setState({ dataToDownload: dataToDownload }, () => {
                    // Use a timeout to allow the state to propagate to the CSVLink control before clicking the button
                    window.setTimeout(() => {
                        const link = this.csvLink.current?.link as HTMLLinkElement;
                        link.click();
                    }, 100);
                });
            })
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to export interludes', error)] });
            })
            .finally(() => this.setState({ exporting: false }));
    };

    render() {
        const disabled = this.state.loading || this.state.exporting || this.state.deleting;
        const csvFilename = `Interludes-${this.props.dataStage}.csv`;
        return (
            <div>
                <Modal
                    visible={this.state.showDeleteModal}
                    header="Delete Interlude Template?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDeleteModal: false })}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.onItemsDelete();
                                    this.setState({ showDeleteModal: false });
                                }}
                            >
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will delete Interlude Template and linked associations.
                </Modal>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.templates}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveColumnWidth}
                    onContentSelectionChange={this.saveColumnSelection}
                    onWrapLinesChange={this.wrapLinesChanged}
                    header={
                        <InterludeTableButtons
                            showDeleteModal={this.openDeleteModal}
                            exportInterludes={this.onItemsExport}
                            totalItems={this.state.templates.length}
                            selectedItems={this.state.selectedTemplates}
                            loading={this.state.loading}
                            onReload={this.loadItems}
                            exporting={this.state.exporting}
                            deleting={this.state.deleting}
                        />
                    }
                    loading={this.state.loading && !this.state.firstPageDone}
                    loadingText="Loading Interludes info"
                    noMatch={<TableNoMatchState />}
                    wrapLines={this.state.wrapLines}
                >
                    <TableFiltering
                        disabled={!this.state.firstPageDone || this.state.deleting}
                        filteringText={this.state.filteringText}
                        filteringFunction={filteringFunction}
                        filteringPlaceholder="Find Interludes"
                        onFilteringChange={this.historyState.filteringChanged}
                    />
                    <TablePagination
                        disabled={!this.state.firstPageDone || this.state.exporting || this.state.deleting}
                        pageSize={this.state.pageSize}
                        onPaginationChange={this.paginationChanged}
                    />
                    <TableSorting
                        sortableColumns={SORTABLE_COLUMNS}
                        disabled={disabled}
                        sortingColumn={this.state.sortingDetail.sortingColumn}
                        sortingDescending={this.state.sortingDetail.sortingDescending}
                        onSortingChange={this.sortingChanged}
                    />
                    <TableSelection
                        selectedItems={this.state.selectedTemplates}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={this.state.contentSelector} />
                    </TablePreferences>
                </Table>
                <CSVLink
                    data={this.state.dataToDownload}
                    filename={csvFilename}
                    target="_blank"
                    className="awsui-util-hide"
                    ref={this.csvLink}
                />
            </div>
        );
    }
}

export default withDataStage(InterludesTable);
